export default {
    name: 'fulfillmentShipCancelPage',
    before: [],
    after: [],
    setup: (app) => {
        const routeName = 'fulfillmentCancel';
        const route = app.routes.getItem(routeName);

        if (route) {
            const routeProps = route.props;

            route.props = (state) => ({
                ...routeProps(state),
                packPageRoute: `/fulfillment-ship/main`,
                packingPageRoute: `/fulfillment-ship/details`,
                pickPageRoute:  `/fulfillment-ship/main`,
                pickingPageRoute: `/fulfillment-ship/details`,
            });
            app.routes.changeItem(routeName, route);
        }
    },
};
