import OnHoldButton from './packages/OnHoldButton';

export default {
    name: 'orderDetailsPage',
    before: [],
    after: [],
    setup: (app) => {
        const orderDetails = app.routes.getItem('orderDetails');

        if (orderDetails) {
            const orderDetailsProps = orderDetails.props;

            orderDetails.props = (state) => ({
                ...orderDetailsProps(state),
                customOrderButtons: [OnHoldButton],
                statusesToCancelOrder: ['Open', 'In Fulfillment', 'Partial Fulfillment'],
            });

            app.routes.changeItem('orderDetails', orderDetails);
        }
    },
};
