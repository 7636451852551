import GraetersShipmentDetails from '../shipmentDetailsPage/packages/ShippingPrintLabel/components/GraetersShipmentDetails';
import { customBatchingItemsTableConfig } from './helpers/batchingPrintCustom';

export default {
    name: 'fulfillmentsBatchingPrintPage',
    before: [],
    after: [],
    setup: (app) => {
        const routeName = 'fulfillmentShipBatchingPrint';
        const route = app.routes.getItem(routeName);
        if (route) {
            const batchingPrintProps = route.props;

            route.props = (state) => ({
                ...batchingPrintProps(state),
                shipmentDetailsComponent: GraetersShipmentDetails,
                customTableData: customBatchingItemsTableConfig,
                customBatchItemsFields: ['quantity_cases', 'quantity_individual'],
            });
            app.routes.changeItem(routeName, route);
        }
    },
};
