import values from 'lodash/values';
import withSettingsTabsWrapper from '../../modules/settings/components/withSettingsTabsWrapper';

export const PREFFERED_WAREHOUSES_ROUTE = '/settings/warehouses/preferred';

export default {
    name: 'preferredWarehousesPage',
    before: [],
    after: [],
    setup: (app) => {
        const route = PREFFERED_WAREHOUSES_ROUTE;
        const label = 'Preferred Warehouses';
        const permission = 'warehouses.index';

        const settingsWarehouseTab = app.settingsTabs.getItem('warehouses');
        const warehouseTabs = values(app.settingsWarehousesTabs.getAll());

        app.settingsTabs.changeItem('warehouses', {
            ...settingsWarehouseTab,
            children: [
                ...warehouseTabs.slice(0, 1),
                {
                    label,
                    link: route,
                    permission,
                    order: 1,
                },
                ...warehouseTabs.slice(1),
            ],
        });

        app.routes.addItem('preferredWarehousesRoute', {
            path: route,
            title: label,
            permission,
            props: () => ({}),
            loader: () => import('./packages/PreferredWarehouses/core'),
            render: withSettingsTabsWrapper(app),
        });
    },
};
