import { set } from 'lodash';

export default {
    name: 'fulfillmentsBatchingPage',
    before: [],
    after: [],
    setup: (app) => {
        const routeName = 'fulfillmentShipBatching';
        const route = app.routes.getItem(routeName);
        if (route) {
            const routeProps = route.props;

            const customGridData = (gridProps) => {
                set(gridProps, 'config.isLoading', false);
                set(gridProps, 'config.adjustHeight', false);
                set(gridProps, 'refreshConfig.initInterval', 5000);
                return gridProps;
            };

            const customPickListData = (gridProps) => {
                set(gridProps, 'config.isLoading', false);
                set(gridProps, 'config.adjustHeight', false);
                return gridProps;
            };

            route.props = (state) => ({
                ...routeProps(state),
                customGridData,
                customPickListData,
            });

            route.loader = () => import('./packages/FulfillmentBatching/core');

            app.routes.changeItem(routeName, route);
        }
    },
};
