import GraetersShipmentDetails from './packages/ShippingPrintLabel/components/GraetersShipmentDetails';

export default {
    name: 'shipmentDetailsPage',
    before: [],
    after: [],
    setup: (app) => {
        const routeName = 'shipment';
        const route = app.routes.getItem(routeName);

        if (route) {
            const shipmentProps = route.props;

            route.props = (state) => ({
                ...shipmentProps(state),
                printShippingDetailsComponent: GraetersShipmentDetails,
            });
            app.routes.changeItem(routeName, route);
        }
    },
};
