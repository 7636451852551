export default {
    name: 'productsGridPage',
    before: [],
    after: [],
    setup: (app) => {
        const productsRoute = app.routes.getItem('products');

        if (productsRoute) {
            productsRoute.loader = () => import('./packages/ExportStock/core');
            app.routes.changeItem('products', productsRoute);
        }
    },
};
