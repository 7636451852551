import GraetersShipmentDetails from '../shipmentDetailsPage/packages/ShippingPrintLabel/components/GraetersShipmentDetails';

export default {
    name: 'fulfillmentShipDetailsPage',
    before: [],
    after: [],
    setup: (app) => {
        const routeName = 'fulfillmentShipDetails';
        const route = app.routes.getItem(routeName);

        if (route) {
            const fulfillmentShipDetailsProps = route.props;

            route.props = (state) => ({
                ...fulfillmentShipDetailsProps(state),
                printShippingDetailsComponent: GraetersShipmentDetails,
            });
            app.routes.changeItem(routeName, route);
        }
    },
};
