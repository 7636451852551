import { findIndex, set } from 'lodash';

export default {
    name: 'fulfillmentsAllShipmentGridPage',
    before: [],
    after: [],
    setup: (app) => {
        const routeName = 'fulfillmentAll';
        const route = app.routes.getItem(routeName);
        if (route) {
            const routeProps = route.props;

            const customGridData = (gridProps) => {
                const orderColIndex = findIndex(gridProps.columns, ({ label }) => label === 'Order');
                if (orderColIndex >= 0) {
                    set(gridProps.columns[orderColIndex], 'field', 'relatedOrder.id');
                }

                gridProps.columns.splice(5, 0, {
                    field: 'relatedOrder.dispatch_date',
                    label: 'Dispatch Date',
                    type: 'date',
                    isFilterable: true,
                    isSortable: true,
                });

                return gridProps;
            };

            route.props = (state) => ({
                ...routeProps(state),
                customGridData,
            });
            app.routes.changeItem(routeName, route);
        }
    },
};
