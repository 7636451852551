import React from 'react';
import values from 'lodash/values';
import { Button, withTabs } from '@skunexus/component-catalog';
import get from 'lodash/get';
import set from 'lodash/set';
import { filterAccessibleItems } from '../../../utils';
import withTabsLayoutWrapper from '../../../utils/withTabsLayoutWrapper';

export default {
    name: 'productDetailsPage',
    before: [],
    after: [],
    setup: (app) => {
        const productDetailsOverview = app.routes.getItem('productDetailsOverview');

        if (productDetailsOverview) {
            const mapProps = (props) => {
                const newProps = { ...props };
                const onHand = {
                    ...get(newProps, 'config.statuses.onHand', {}),
                    label: (
                        <Button
                            color="link"
                            style={{
                                color: 'white',
                                fontSize: 'var(--sn-label-lg-font-size)',
                                fontWeight: 'var(--sn-label-lg-font-weight)',
                                lineHeight: 1,
                            }}
                            onClick={() => {
                                newProps.history.push(`/products/stock/${newProps.params.id}`);
                            }}
                        >
                            On Hand
                        </Button>
                    ),
                };
                set(newProps, 'config.statuses.onHand', onHand);
                return newProps;
            };

            productDetailsOverview.render = (Component) =>
                withTabsLayoutWrapper((props) =>
                    withTabs(filterAccessibleItems(values(app.productDetailsTabs.getAll())))(Component)(
                        mapProps(props),
                    ),
                );

            app.routes.changeItem('productDetailsOverview', productDetailsOverview);
        }
    },
};
